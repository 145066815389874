import ApiService from "@/core/services/api.service";

export default {

    // -----API HISTORY LIST------
    getSendDocAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/nasional/distribusi/send-doc/all", { params: credentials })
    },
    getSendDocSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/nasional/distribusi/send-doc/edit", { params: credentials })
    },
    verifSendDoc(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/nasional/distribusi/send-doc", credentials)
    },


    getPerbaikanSendDocAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/perbaikan/nasional/distribusi/send-doc/all", { params: credentials })
    },
    getPerbaikanSendDocSingle(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/perbaikan/nasional/distribusi/send-doc/edit", { params: credentials })
    },
    verifPerbaikanSendDoc(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/perbaikan/nasional/distribusi/send-doc", credentials)
    },
}